import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography, Breadcrumb, Tabs, Button } from 'antd';
import { apiPost, apiGet, apiPut } from '../../../utils/api';
import { GIFT, COUPON } from '../../../constants/apiPath';

import GiftForm from '../../../components/gift-form/gift-form.component';
import CouponForm from '../../../components/coupon-form/coupon-form.component';
import PageLoader from '../../../components/shared/page-loader/page-loader.component';
import CouponTable from '../../../components/coupon-table/coupon-table.component';
import CouponUploadDialog from '../../../components/coupon-upload-dialog/coupon-upload-dialog';

import { getBase64 } from '../../../utils/base64';
import { getLocalDateTime, getUTCDateString } from '../../../utils/date';

import './gift-detail.style.css';

const { Title } = Typography;
const { TabPane } = Tabs;

const GiftDetailPage = ({ match, history }) => {
  const [giftSubmitInProgress, setGiftSubmitInProgress] = useState(false);
  const [couponSubmitInProgress, setCouponSubmitInProgress] = useState(false);
  const [giftFetchInProgress, setGiftFetchInProgress] = useState(false);
  const [gift, setGift] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [showCouponUploadDialog, setShowCouponUploadDialog] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);
  const [couponImageBase64, setCouponImageBase64] = useState(null);

  const uuid = match.params.uuid;
  const title = uuid ? 'Ajándék szerkesztés' : 'Ajándék létrehozás';

  const submitGift = async gift => {
    if (imageBase64) {
      gift.cover = imageBase64;
    }
    const data = {
      ...gift,
      startAt: getUTCDateString(gift.startAt),
      endAt: getUTCDateString(gift.endAt),
      validAt: getUTCDateString(gift.validAt),
    };
    try {
      setGiftSubmitInProgress(true);
      if (uuid) {
        await apiPut({
          url: `${GIFT}/${uuid}`,
          data,
          showNotificaton: true,
        });
      } else {
        const newGift = await apiPost({
          url: GIFT,
          data,
          showNotificaton: true,
        });
        history.push(`${newGift.uuid}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGiftSubmitInProgress(false);
    }
  };

  const closeCouponUploadDialog = () => {
    setShowCouponUploadDialog(false);
  };

  const handleCouponUsersDownload = async ({ uuid }) => {
    try {
      await apiGet({
        url: `${COUPON}/csv/${uuid}`,
        showNotificaton: true,
        download: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const submitCoupon = async coupon => {
    if (couponImageBase64) {
      coupon.url = couponImageBase64;
    }
    try {
      setCouponSubmitInProgress(true);
      if (selectedCoupon?.uuid) {
        await apiPut({
          url: `${COUPON}/${uuid}`,
          data: { ...coupon, uuid: selectedCoupon.uuid },
          showNotificaton: true,
        });
        setSelectedCoupon(null);
      } else {
        await apiPost({
          url: `${COUPON}/${uuid}`,
          data: coupon,
          showNotificaton: true,
        });
      }
      setShowCouponForm(false);
    } catch (err) {
      console.log(err);
    } finally {
      setCouponSubmitInProgress(false);
    }
  };

  const deleteCoupon = async ({
    deleted,
    uuid,
    url,
    code,
    tableData,
    setTableData,
  }) => {
    try {
      await apiPut({
        url: `${COUPON}/${uuid}`,
        data: { url, code, uuid, deleted: true },
        showNotificaton: true,
      });
      setTableData(tableData.filter(data => data.uuid !== uuid));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchGift = async () => {
    try {
      setGiftFetchInProgress(true);
      setShowCouponUploadDialog(false);
      const gift = await apiGet({ url: `${GIFT}/${uuid}` });
      setGift({
        ...gift,
        startAt: getLocalDateTime(gift.startAt),
        endAt: getLocalDateTime(gift.endAt),
        validAt: getLocalDateTime(gift.validAt),
      });
    } catch (err) {
      console.log(err);
    } finally {
      setGiftFetchInProgress(false);
    }
  };

  useEffect(() => {
    if (uuid && !gift) {
      fetchGift();
    }
  }, []);
  if (giftFetchInProgress) {
    return <PageLoader />;
  }
  return (
    <div className="dashboard-gift-detail-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/dashboard/gift'}>Ajándékok</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-gift-detail-page__header">
        <div className="dashboard-gift-detail-page__header-title">
          <Title level={2}>{title}</Title>
        </div>
      </div>
      <div className="dashboard-gift-detail-page__tab">
        <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab={'Ajándék adatok'} key={1}>
            <GiftForm
              onSubmit={submitGift}
              onImageLoaded={file =>
                getBase64(file, result => setImageBase64(result))
              }
              isLoading={giftSubmitInProgress}
              gift={gift}
            />
          </TabPane>
          {uuid && (
            <TabPane tab={'Kuponok'} key={2}>
              {!showCouponForm && (
                <>
                  <div className="gift-detail-coupon__btn-container">
                    <Button
                      type="dashed"
                      onClick={() => setShowCouponUploadDialog(true)}
                    >
                      Kupon excel feltöltés
                    </Button>
                    <Button
                      type="dashed"
                      onClick={() => setShowCouponForm(true)}
                    >
                      Kupon hozzáadása
                    </Button>
                  </div>
                  <div className="gift-detail-coupon__list-container">
                    <CouponTable
                      giftUUID={gift?.uuid}
                      onSelect={coupon => {
                        setSelectedCoupon(coupon);
                        setShowCouponForm(true);
                      }}
                      onCouponUsersDownload={handleCouponUsersDownload}
                      onDelete={deleteCoupon}
                    />
                  </div>
                </>
              )}
              {showCouponForm && (
                <CouponForm
                  onSubmit={value => submitCoupon(value)}
                  onImageLoaded={file =>
                    getBase64(file, result => setCouponImageBase64(result))
                  }
                  onCancel={() => {
                    setShowCouponForm(false);
                    setSelectedCoupon(null);
                  }}
                  isLoading={couponSubmitInProgress}
                  coupon={selectedCoupon}
                />
              )}
              <CouponUploadDialog
                isModalVisible={showCouponUploadDialog}
                handleCancel={closeCouponUploadDialog}
                giftUUID={uuid}
                handleUploadSuccess={fetchGift}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default withRouter(GiftDetailPage);
