import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Popconfirm } from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getBase64 } from '../../../utils/base64';

function beforeUpload(file) {
  console.log(file.type);
  const isVideo = file.type.indexOf('video') !== -1;
  if (!isVideo) {
    message.error('You can only upload video file!');
  }
  return isVideo;
}

const VideoUpload = ({ onVideoLoaded, url = '', handleDelete = () => {} }) => {
  const [videoUrl, setVideoUrl] = useState(url);

  useEffect(() => {
    setVideoUrl(url);
    return function cleanup() {
      setVideoUrl(null);
    };
  }, [url]);

  const handleLoad = info => {
    getBase64(info.file, vUrl => {
      setVideoUrl(vUrl);
    });
    onVideoLoaded(info.file);
  };

  return (
    <div>
      <Upload
        name="video-upload"
        listType="picture"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={info => handleLoad(info)}
      >
        {videoUrl ? (
          <video
            controls
            src={videoUrl}
            alt="file upload"
            style={{ maxWidth: '200px', maxHeight: '200px' }}
          />
        ) : (
          <Button>
            <UploadOutlined /> Feltöltés
          </Button>
        )}
      </Upload>
      {videoUrl && (
        <Popconfirm
          title="Biztos a törlésben"
          cancelText="Mégsem"
          okText="Igen"
          onConfirm={() => {
            setVideoUrl(null);
            handleDelete();
          }}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button
            type="danger"
            style={{ marginLeft: '10px' }}
            icon={<DeleteOutlined />}
            size={'small'}
          />
        </Popconfirm>
      )}
    </div>
  );
};

export default VideoUpload;
