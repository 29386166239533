import React, { useEffect, useContext, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Result, Button } from 'antd';
import {
  store as userStore,
  USER_UNAUTHENTICATED,
  USER_AUTHENTICATED,
} from '../../../providers/userProvider';
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ component: Component, history, ...rest }) => {
  const { state, dispatch } = useContext(userStore);
  const { isAuthenticated } = state;
  const [allowVisit, setAllowVisit] = useState(true);

  useEffect(() => {
    let token = localStorage.getItem('opinio_token');
    if (token) {
      let tokenExpiration = jwtDecode(token).exp;
      let dateNow = new Date();

      if (tokenExpiration < dateNow.getTime() / 1000) {
        dispatch({ type: USER_UNAUTHENTICATED });
        setAllowVisit(false);
      } else {
        dispatch({ type: USER_AUTHENTICATED });
        setAllowVisit(true);
      }
    } else {
      dispatch({ type: USER_UNAUTHENTICATED });
      setAllowVisit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Route
      {...rest}
      render={props =>
        !allowVisit ? (
          <Result
            status="403"
            title="403"
            subTitle="Sajnáljuk, ehhez az oldalhoz nincs jogosultsága."
            extra={
              <Button
                onClick={() => {
                  history.push('/login');
                }}
                type="primary"
              >
                Vissza a bejelentkező képernyőre
              </Button>
            }
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
