import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import {
  getNormalFormConfig,
  getProfileFormConfig,
  layoutConfig,
} from './survey-form.config.js';

import './survey-form.style.css';

const SurveyForm = ({
  onSubmit,
  isLoading,
  survey,
  tagList,
  filterList,
  type = 'normal',
}) => {
  const { tags, ...formData } = survey || {};
  let config =
    type === 'normal'
      ? getNormalFormConfig(tagList, filterList)
      : getProfileFormConfig(tagList, filterList);

  if (!!survey && type === 'normal') {
    let itemWebTitle = config.items.filter(item => item.name == 'webTitle')[0];
    itemWebTitle.placeholder = 'A mai kérdőív szlogenje: ' + survey.notificationTitle + ' ' + survey.notificationMessage;
  }

  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Mentés'}
      isLoading={isLoading}
      initialData={{ ...formData, tags: tags?.map(tag => tag.content) }}
    />
  );
};

const areEqual = (prevProps, nextProps) => {
  if (prevProps.tagList !== nextProps.tagList) {
    return false;
  }
  return prevProps.survey === nextProps.survey;
};

export default React.memo(SurveyForm, areEqual);
