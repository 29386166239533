import React from 'react';
import { Input } from 'antd';
import { getLocalDateTimeString } from '../../utils/date';

const { Search } = Input;

export const getTableConfig = ({ search }) => {
  return [
    {
      title: 'Kérdés',
      dataIndex: 'content',
      key: 'content',
      defaultSortOrder: 'ascend',
      sorter: true,
      align: 'center',
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'content', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Válasz dátuma',
      dataIndex: 'votedAt',
      key: 'votedAt',
      defaultSortOrder: 'ascend',
      sorter: true,
      align: 'center',
      render: votedAt => <span>{getLocalDateTimeString(votedAt)}</span>,
    },
    {
      title: 'OPont',
      dataIndex: 'point',
      key: 'point',
      defaultSortOrder: 'ascend',
      sorter: true,
      align: 'center',
    },
  ];
};
