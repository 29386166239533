import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './gift-form.config.js';

import './gift-form.style.css';

const GiftForm = ({ onSubmit, isLoading, gift, onImageLoaded }) => {
  let config = getFormConfig({ onImageLoaded, url: gift?.cover });
  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Mentés'}
      isLoading={isLoading}
      initialData={gift}
    />
  );
};

export default GiftForm;
