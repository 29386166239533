/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Typography, Input, Button, message } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  SURVEY,
  QUESTION,
  USER_FILTER,
  USER,
} from '../../../constants/apiPath';
import { apiGet, apiPost, apiPut } from '../../../utils/api';
import BaseSelector from '../../../components/filter/base-selector/base-selector.component';
import ProfileSelector from '../../../components/filter/profile-selector/profile-selector.component';
import VoteSelector from '../../../components/filter/vote-selector/vote-selector.component';
import ActivitySelector from '../../../components/filter/activity-selector/activity-selector.component';
import { getLocalDateTimeString } from '../../../utils/date';

import './filter-detail.style.css';

const { Title } = Typography;
const { Text } = Typography;

const FilterDetailPage = ({ history, match }) => {
  const uuid = match.params.uuid;
  const title = uuid ? 'Szűrő szerkesztés' : 'Szűrő létrehozás';
  const [filterValues, setFilterValues] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    const fectchSurveys = async () => {
      const surveys = await apiGet({ url: `${SURVEY}/all` });
      setSurveys(surveys);
    };
    if (uuid) {
      const fetchFilter = async () => {
        const filter = await apiGet({ url: `${USER_FILTER}/${uuid}` });
        setFilterName(filter.name);
        setUserCount(filter.userCount);
        const surveyQuestionPromises = [];
        filter.conditions.forEach(condition => {
          if (condition.type === 'vote') {
            surveyQuestionPromises.push(
              apiGet({ url: `${QUESTION}/answer/${condition.value}` })
            );
          }
        });
        const surveyQuestions = await Promise.all(surveyQuestionPromises);
        const transformedConditions = filter.conditions.map(condition => {
          let transformedCondition = {
            type: condition.type,
            relation: condition.relation,
            field: condition.field,
            operation: condition.operation,
            value: condition.value,
            id: condition.id,
          };
          // vote needs additional field to display, we have too find questions and answers based on the single answer id
          if (condition.type === 'vote') {
            surveyQuestions.forEach(surveyQuestion => {
              surveyQuestion.questions.forEach(question => {
                question.answers.forEach(answer => {
                  if (answer.id === Number(condition.value)) {
                    transformedCondition.surveyUUID = surveyQuestion.surveyUUID;
                    transformedCondition.questions = surveyQuestion.questions;
                    transformedCondition.questionId = question.id;
                    transformedCondition.answers = question.answers;
                    transformedCondition.answerId = answer.id;
                  }
                });
              });
            });
          }
          return transformedCondition;
        });
        setFilterValues(transformedConditions);
      };
      fetchFilter();
    }
    fectchSurveys();
  }, []);

  const handleExport = async () => {
    try {
      await apiGet({
        url: `${USER}/csv/${uuid}`,
        showNotificaton: true,
        download: true,
        fileName: `${filterName}_${getLocalDateTimeString()}.csv`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const addFilter = () => {
    setFilterValues([
      ...filterValues,
      {
        type: undefined,
        relation: undefined,
        field: undefined,
        operation: undefined,
        value: undefined,
        id: undefined,
      },
    ]);
  };

  const removeFilter = idx => {
    const filteredItems = [];
    filterValues.forEach((value, index) => {
      if (index !== idx) {
        if (idx === 0 && index === 1) {
          filteredItems.push({ ...value, relation: 'and' });
        } else {
          filteredItems.push({ ...value });
        }
      }
    });
    setFilterValues(filteredItems);
  };
  const handleChange = ({ value, idx, field }) => {
    setFilterValues(
      filterValues.map((filterValue, index) => {
        if (index === idx) {
          let newValues = { ...filterValue, [field]: value };
          if (field === 'field') {
            newValues.value = undefined;
          }
          if (field === 'type') {
            newValues.relation = undefined;
            newValues.field = undefined;
            newValues.operation = undefined;
            newValues.value = undefined;
          }
          return newValues;
        }
        return { ...filterValue };
      })
    );
  };

  const handleSurveyChange = async ({ uuid, idx }) => {
    const questions = await apiGet({ url: `${QUESTION}/${uuid}` });
    setFilterValues(
      filterValues.map((filterValue, index) => {
        if (index === idx) {
          let newValues = {
            ...filterValue,
            questions,
            answers: undefined,
            questionId: undefined,
            answerId: undefined,
            surveyUUID: uuid,
          };
          return newValues;
        }
        return { ...filterValue };
      })
    );
  };

  const handleQuestionChange = async ({ id, idx }) => {
    setFilterValues(
      filterValues.map((filterValue, index) => {
        if (index === idx) {
          const question = filterValue?.questions?.find(
            question => question.id === id
          );
          let newValues = {
            ...filterValue,
            answers: question.answers,
            answerId: undefined,
            questionId: id,
          };
          return newValues;
        }
        return { ...filterValue };
      })
    );
  };

  const handleAnswerChange = async ({ id, idx }) => {
    setFilterValues(
      filterValues.map((filterValue, index) => {
        if (index === idx) {
          let newValues = {
            ...filterValue,
            answerId: id,
          };
          return newValues;
        }
        return { ...filterValue };
      })
    );
  };
  const transformValuesForSubmit = () => {
    const dataToSave = filterValues.reduce(
      (acc, curr) => {
        let val = {
          relation: curr.relation,
          field: curr.field,
          operation: curr.operation,
          type: curr.type,
          value: curr.value,
          id: curr.id,
        };
        if (curr.type === 'vote') {
          val = {
            ...val,
            field: 'survey_answer_id',
            operation: '=',
            value: curr.answerId,
          };
        }
        acc.conditions.push(val);
        return acc;
      },
      {
        name: filterName,
        conditions: [],
      }
    );
    return dataToSave;
  };
  const validateFilter = filterData => {
    if (!filterData.name.trim().length) {
      message.error('Szűrő neve nem lehet üres');
      return false;
    }
    if (filterData.conditions.length === 0) {
      message.error('Hiányzó szűrőfeltétel');
      return false;
    }
    let missingData = false;
    filterData.conditions.forEach(conditions => {
      const { relation, field, operation, type, value } = conditions;
      if (!relation || !field || !operation || !type || !value) {
        missingData = true;
      }
    });
    if (missingData) {
      message.error('Hiányzó szűrőmező');
      return false;
    }
    return true;
  };
  const handleSave = async () => {
    const dataToSave = transformValuesForSubmit();
    const isValidFilter = validateFilter(dataToSave);
    if (!isValidFilter) {
      return;
    }
    try {
      if (uuid) {
        const filter = await apiPut({
          url: `${USER_FILTER}/${uuid}`,
          data: dataToSave,
          showNotificaton: true,
        });
        setUserCount(filter.userCount);
      } else {
        const filter = await apiPost({
          url: USER_FILTER,
          data: dataToSave,
          showNotificaton: true,
        });
        setUserCount(filter.userCount);
        history.push(`${filter.uuid}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="dashboard-tag-filter-page">
      <div className="dashboard-filter-detail-page__header">
        <div className="dashboard-filter-detail-page__header-title">
          <Title level={2}>{title}</Title>
        </div>
        <div className="dashboard-filter-detail-page__header-counter">
          <Title level={2}>Találat: {userCount}</Title>
        </div>
      </div>
      <div className="dashboard-filter-detail-page__form">
        <div data-testid="filter-form-container">
          <div className="filter-form-title">
            <Text className="filter-form-title__label">Szűrő megnevezése:</Text>
            <Input
              className="filter-form-title__input"
              value={filterName}
              onChange={e => setFilterName(e.target.value)}
              placeholder="Szűrő neve"
            />
          </div>
          <div className="filter-items">
            {filterValues.map((filterValue, idx) => (
              <div className="filter-item" key={Math.random()}>
                <BaseSelector
                  handleChange={handleChange}
                  filterValue={filterValue}
                  idx={idx}
                />
                {filterValue?.type === 'profile' && filterValue.relation && (
                  <ProfileSelector
                    handleChange={handleChange}
                    filterValue={filterValue}
                    idx={idx}
                  />
                )}
                {filterValue?.type === 'activity' && filterValue.relation && (
                  <ActivitySelector
                    handleChange={handleChange}
                    filterValue={filterValue}
                    idx={idx}
                  />
                )}
                {filterValue?.type === 'vote' && filterValue?.relation && (
                  <VoteSelector
                    handleSurveyChange={handleSurveyChange}
                    handleQuestionChange={handleQuestionChange}
                    handleAnswerChange={handleAnswerChange}
                    filterValue={filterValue}
                    idx={idx}
                    surveys={surveys}
                  />
                )}
                {filterValues.length > 1 ? (
                  <MinusCircleOutlined
                    className="filter-delete-button"
                    style={{ margin: '0 8px' }}
                    onClick={() => {
                      removeFilter(idx);
                    }}
                  />
                ) : null}
              </div>
            ))}
          </div>
          <div className="filter-buttons">
            <Button
              type="dashed"
              onClick={() => {
                addFilter();
              }}
              style={{ width: '60%' }}
            >
              <PlusOutlined /> Szűrő hozzáadás
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleSave();
              }}
              style={{ width: '10%', marginLeft: '10px' }}
            >
              <SaveOutlined /> Mentés
            </Button>
            {uuid && (
              <Button
                type="dashed"
                onClick={() => {
                  handleExport();
                }}
                style={{ width: '10%', marginLeft: '10px' }}
              >
                <DownloadOutlined /> Exportálás
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FilterDetailPage);
