export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = () => ({
  name: 'tag-form',
  items: [
    {
      type: 'input',
      name: 'content',
      label: 'Címke',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
  ],
  submitBtnLabel: 'Mentés',
});
