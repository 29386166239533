import moment from 'moment';

export const getUTCDateString = date => {
  if (!date) return;
  const formattedDate = moment
    .utc(date)
    .set('second', 0)
    .format('YYYY-MM-DD HH:mm:ss');
  return formattedDate;
};
export const getLocalDateTime = date => {
  const local = moment
    .utc(date)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
  return moment(local);
};

export const getLocalDateTimeString = date => {
  const local = moment
    .utc(date)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
  return local;
};
