import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './push-form.config.js';

import './push-form.style.css';

const PushForm = ({ onSubmit, isLoading, filterList }) => {
  let config = getFormConfig(filterList);
  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Küldés'}
      isLoading={isLoading}
      resetFields={true}
    />
  );
};

export default PushForm;
