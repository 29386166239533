import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './answered-question-table.config.js';
import { QUESTION } from '../../constants/apiPath';

const AnsweredQuestionTable = ({ userUUID }) => {
  return (
    <ApiTable
      url={`${QUESTION}/answered/${userUUID}`}
      getTableConfig={getTableConfig}
      defaultOrder={'descend'}
      defaultOrderField={'votedAt'}
      defaultFilterField={'content'}
      defaultFilterValue={''}
      size={'middle'}
    />
  );
};

export default AnsweredQuestionTable;
