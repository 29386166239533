import React from 'react';
import { Typography, Button } from 'antd';
import { withRouter } from 'react-router';
import { apiPut } from '../../../utils/api';
import { GIFT } from '../../../constants/apiPath';

import GiftTable from '../../../components/gift-table/gift-table.component';

import './gift-list.style.css';

const { Title } = Typography;

const GiftListPage = ({ history }) => {
  const onDelete = async ({ deleted, uuid, tableData, setTableData }) => {
    try {
      await apiPut({
        url: `${GIFT}/${uuid}`,
        data: { deleted },
        showNotificaton: true,
      });
      setTableData(tableData.filter(data => data.uuid !== uuid));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="dashboard-gift-page">
      <div className="dashboard-gift-page__header">
        <div className="dashboard-gift-page__header-title">
          <Title level={2}>Ajándékok</Title>
        </div>
        <div className="dashboard-gift-page__header-btn">
          <Button type="dashed" onClick={() => history.push('gift/new')}>
            Ajándék hozzáadása
          </Button>
        </div>
      </div>
      <div className="dashboard-gift-page__table">
        <GiftTable onDelete={onDelete} />
      </div>
    </div>
  );
};

export default withRouter(GiftListPage);
