import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography, Tabs, Breadcrumb, Button } from 'antd';
import { apiPost, apiGet, apiPut, apiDelete } from '../../../utils/api';
import { USER, GIFT } from '../../../constants/apiPath';

import UserForm from '../../../components/user-form/user-form.component';
import PageLoader from '../../../components/shared/page-loader/page-loader.component';
import AnsweredQuestionTable from '../../../components/answered-question-table/answered-question-table.component';
import UserCouponTable from '../../../components/user-coupon-table/user-coupon-table.component';
import CustomPointTable from '../../../components/custom-point-table/custom-point-table.component';
import CustomPointForm from '../../../components/custom-point-form/custom-point-form.component';

import './user-detail.style.css';

const { Title } = Typography;
const { TabPane } = Tabs;

const UserDetailPage = ({ match, history, location }) => {
  const [userSubmitInProgress, setUserSubmitInProgress] = useState(false);
  const [customPointSubmitInProgress, setCustomSubmitInProgress] = useState(
    false
  );
  const [userFetchProgress, setUserFetchInProgress] = useState(false);
  const [user, setUser] = useState(null);
  const [showPointTable, setShowPointTable] = useState(true);
  const [point, setPoint] = useState(0);
  const uuid = match.params.uuid;

  const submitUser = async user => {
    try {
      setUserSubmitInProgress(true);
      if (uuid) {
        const updatedUser = await apiPut({
          url: USER,
          data: { ...user, uuid },
          showNotificaton: true,
        });
        setUser(updatedUser);
      } else {
        const newUser = await apiPost({
          url: USER,
          data: user,
          showNotificaton: true,
        });
        setUser(newUser.user);
        history.push(`${newUser.user.uuid}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUserSubmitInProgress(false);
    }
  };

  const submitCustomPoint = async pointDetails => {
    try {
      await apiPost({
        url: `${USER}/custompoint`,
        data: { ...pointDetails, userUUID: uuid, type: 'custom' },
        showNotificaton: true,
      });
      setShowPointTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setCustomSubmitInProgress(false);
    }
  };

  const deleteCustomPoint = async ({
    uuid,
    pointValue,
    tableData,
    setTableData,
  }) => {
    try {
      await apiDelete({
        url: `${USER}/custompoint/${uuid}`,
        showNotificaton: true,
      });
      setTableData(tableData.filter(data => data.uuid !== uuid));
      setPoint(point - pointValue);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!uuid || user) {
      return;
    }
    const fetchUser = async () => {
      try {
        setUserFetchInProgress(true);
        const user = await apiGet({ url: `${USER}/${uuid}` });
        setUser(user);
      } catch (err) {
        console.log(err);
      } finally {
        setUserFetchInProgress(false);
      }
    };
    fetchUser();
  }, [user, uuid]);

  useEffect(() => {
    if (!uuid) {
      return;
    }
    const fetchPoints = async () => {
      try {
        const resp = await apiGet({ url: `${GIFT}/user/point/${uuid}` });
        setPoint(resp.point);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPoints();
  }, [showPointTable, uuid]);

  if (userFetchProgress) {
    return <PageLoader />;
  }
  return (
    <div className="dashboard-user-detail-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/dashboard/users'}>Felhasználók</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {uuid ? 'Felhasználó szerkesztés' : 'Felhasználó létrehozás'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-user-detail-page__header">
        <div className="dashboard-user-detail-page__header-title">
          <Title level={2}>
            {uuid ? 'Felhasználó szerkesztés' : 'Felhasználó létrehozás'}
          </Title>
        </div>
      </div>
      <div className="dashboard-user-detail-page__tab">
        <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab={'Alapadatok'} key={1}>
            <UserForm
              onSubmit={submitUser}
              isLoading={userSubmitInProgress}
              user={user}
            />
          </TabPane>
          {uuid && (
            <TabPane tab={'Pontok'} key={2}>
              {showPointTable && (
                <>
                  <div className="dashboard-user-detail-page__point-header">
                    <Title level={3}>Összes pont: {point}</Title>
                    <Button
                      type="dashed"
                      onClick={() => setShowPointTable(false)}
                    >
                      Pont hozzáadása
                    </Button>
                  </div>
                  <Title level={3}>
                    Elmúlt 3 hónapban megválaszolt kérdések
                  </Title>
                  <AnsweredQuestionTable
                    userUUID={uuid}
                  ></AnsweredQuestionTable>
                  <Title level={3}>Elmúlt 3 hónapban beváltott ajándékok</Title>
                  <UserCouponTable userUUID={uuid}></UserCouponTable>
                  <Title level={3}>Elmúlt 3 hónapban hozzáadott pontok</Title>
                  <CustomPointTable
                    onDelete={deleteCustomPoint}
                    userUUID={uuid}
                  ></CustomPointTable>
                </>
              )}
              {!showPointTable && (
                <CustomPointForm
                  onSubmit={submitCustomPoint}
                  isLoading={customPointSubmitInProgress}
                  onCancel={() => setShowPointTable(true)}
                />
              )}
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default withRouter(UserDetailPage);
