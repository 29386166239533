import React from 'react';
import { Button } from 'antd';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './custom-point-form.config.js';

import './custom-point-form.style.css';

const CustomPointForm = ({ onSubmit, isLoading, onCancel }) => {
  let config = getFormConfig();
  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Mentés'}
      isLoading={isLoading}
      buttons={[
        <Button key={1} style={{ marginLeft: '10px' }} onClick={onCancel}>
          Mégsem
        </Button>,
      ]}
    />
  );
};

export default CustomPointForm;
