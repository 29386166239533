import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Tag, Button, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  FormOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getLocalDateTimeString } from '../../utils/date';

const { Search } = Input;

export const getTableConfig = ({
  tableData,
  setTableData,
  search,
  onDelete,
}) => {
  return [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      align: 'center',
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'email', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Regisztráció dátuma',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: true,
      align: 'center',
      render: createdAt => <span>{getLocalDateTimeString(createdAt)}</span>,
    },
    {
      title: 'Jogosultság',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      align: 'center',
      render: role => (
        <span>
          {role === 'ADMIN' ? (
            <Tag color={'volcano'}>Admin</Tag>
          ) : (
            <Tag color={'green'}>Felhasználó</Tag>
          )}
        </span>
      ),
    },
    {
      title: 'Műveletek',
      key: 'action',
      render: (text, { uuid, deleted }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Link to={`users/${uuid}`}>
            <Button type="primary" icon={<FormOutlined />} size={'small'} />
          </Link>
          <Popconfirm
            title="Biztos a törlésben"
            cancelText="Mégsem"
            okText="Igen"
            onConfirm={() =>
              onDelete({
                deleted: true,
                uuid: uuid,
                tableData,
                setTableData,
              })
            }
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Button type="danger" icon={<DeleteOutlined />} size={'small'} />
          </Popconfirm>
        </div>
      ),
    },
  ];
};
