import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Tooltip, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  FormOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

const { Search } = Input;

export const getTableConfig = ({
  tableData,
  setTableData,
  search,
  onDelete,
}) => {
  return [
    {
      title: 'Címke',
      dataIndex: 'content',
      key: 'content',
      defaultSortOrder: 'ascend',
      sorter: true,
      align: 'center',
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'content', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Műveletek',
      key: 'action',
      render: (text, { uuid, content }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Link to={`tag/${uuid}`}>
            <Tooltip title="Szerkesztés">
              <Button type="primary" icon={<FormOutlined />} size={'small'} />
            </Tooltip>
          </Link>
          <Popconfirm
            title="Biztos a törlésben"
            cancelText="Mégsem"
            okText="Igen"
            onConfirm={() =>
              onDelete({
                deleted: true,
                uuid: uuid,
                content,
                tableData,
                setTableData,
              })
            }
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Button type="danger" icon={<DeleteOutlined />} size={'small'} />
          </Popconfirm>
        </div>
      ),
    },
  ];
};
