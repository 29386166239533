import React from 'react';
import { Input, Button, Tooltip, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { getLocalDateTimeString } from '../../utils/date';

const { Search } = Input;

export const getTableConfig = ({
  search,
  onSelect,
  onDelete,
  tableData,
  setTableData,
  onCouponUsersDownload,
}) => {
  return [
    {
      title: 'Kód',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      align: 'center',
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'code', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Link',
      dataIndex: 'url',
      key: 'url',
      sorter: true,
      align: 'center',
      render: link =>
        link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            Link megnyitása
          </a>
        ) : (
          ''
        ),
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'link', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Létrehozás dátuma',
      dataIndex: 'createdAt',
      key: 'content',
      defaultSortOrder: 'ascend',
      sorter: true,
      align: 'center',
      render: createdAt => <span>{getLocalDateTimeString(createdAt)}</span>,
    },
    {
      title: 'Műveletek',
      key: 'action',
      render: (text, { uuid, url, code, quantity }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Szerkesztés">
            <Button
              type="primary"
              onClick={() => onSelect({ uuid, url, code, quantity })}
              icon={<FormOutlined />}
              size={'small'}
            />
          </Tooltip>
          <Tooltip title="Kupont beváltók export">
            <Button
              onClick={() => onCouponUsersDownload({ uuid })}
              type="default"
              icon={<DownloadOutlined />}
              size={'small'}
            />
          </Tooltip>
          <Popconfirm
            title="Biztos a törlésben"
            cancelText="Mégsem"
            okText="Igen"
            onConfirm={() =>
              onDelete({ uuid, url, code, tableData, setTableData })
            }
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Button type="danger" icon={<DeleteOutlined />} size={'small'} />
          </Popconfirm>
        </div>
      ),
    },
  ];
};
