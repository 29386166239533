import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { UserProvider } from './providers/userProvider.js';

import LoginPage from './pages/login/login.page';
import PasswordResetPage from './pages/password-reset/password-reset.page';
import DashboardPage from './pages/dashboard/dashboard.page';
import PrivateRoute from './components/shared/private-route/private-route.component';
import './App.css';

function App() {
  return (
    <UserProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/password-reset/:uuid"
            component={PasswordResetPage}
          />
          <PrivateRoute path="/dashboard" component={DashboardPage} />
        </Switch>
      </Router>
    </UserProvider>
  );
}

export default App;
