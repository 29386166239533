import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Typography } from 'antd';
import { apiPost, apiGet } from '../../../utils/api';
import { PUSH, USER_FILTER } from '../../../constants/apiPath';

import PushForm from '../../../components/push-form/push-form.component';
import './push.style.css';
const { Title } = Typography;

const PushPage = () => {
  const [pushSubmitInProgress, setPushSubmitInProgress] = useState(false);
  const title = 'Push üzenet küldése';
  const [filterOptions, setFilterOptions] = useState([]);

  const submitPush = async push => {
    try {
      setPushSubmitInProgress(true);
      await apiPost({
        url: `${PUSH}`,
        data: push,
        showNotificaton: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setPushSubmitInProgress(false);
    }
  };

  useEffect(() => {
    const fetchFilters = async () => {
      const filterList = await apiGet({ url: `${USER_FILTER}/all` });
      setFilterOptions(
        filterList.map(filter => ({ value: filter.uuid, label: filter.name }))
      );
    };
    fetchFilters();
  }, []);

  return (
    <div className="dashboard-push-page">
      <div className="dashboard-push-page__header">
        <div className="dashboard-gift-detail-page__header-title">
          <Title level={2}>{title}</Title>
        </div>
      </div>
      <div className="dashboard-gift-push-page__container">
        <PushForm
          onSubmit={submitPush}
          isLoading={pushSubmitInProgress}
          filterList={filterOptions}
        />
      </div>
    </div>
  );
};

export default withRouter(PushPage);
