import React from 'react';
import moment from 'moment';
import { Typography, Select, DatePicker } from 'antd';
import './profile-selector.style.css';
const { Option } = Select;
const { Text } = Typography;

const ProfileSelector = ({ handleChange, filterValue = {}, idx }) => {
  return (
    <div className="profile-selector">
      <div className="profile-selector__field">
        <Text className="profile-selector__label">Szűrő:</Text>
        <Select
          className="profile-select"
          placeholder="Szűrőmező"
          defaultValue={filterValue.field}
          optionFilterProp="children"
          onChange={value => handleChange({ value, idx, field: 'field' })}
        >
          <Option value="birthYear">Születési év</Option>
          <Option value="settlementType">Lakhely típusa</Option>
          <Option value="gender">Nem</Option>
          <Option value="educationLevel">Iskolai végzettség</Option>
          <Option value="county">Megye</Option>
        </Select>
      </div>
      {filterValue?.field && (
        <div className="profile-selector__field">
          <Text className="profile-selector__label">Operátor:</Text>
          <Select
            placeholder="Reláció"
            className="profile-select"
            defaultValue={filterValue.operation}
            optionFilterProp="children"
            onChange={value => handleChange({ value, idx, field: 'operation' })}
          >
            <Option value="<">&lt;</Option>
            <Option value=">">&gt;</Option>
            <Option value="=">=</Option>
            <Option value="!=">!=</Option>
          </Select>
        </div>
      )}
      {filterValue?.field === 'birthYear' && filterValue?.operation && (
        <div className="profile-selector__field">
          <Text className="profile-selector__label">Dátum:</Text>
          <DatePicker
            picker="year"
            className="profile-select"
            defaultValue={
              filterValue.value ? moment(filterValue.value) : undefined
            }
            onChange={(date, dateString) =>
              handleChange({
                value: dateString,
                idx,
                field: 'value',
              })
            }
          />
        </div>
      )}
      {filterValue?.field === 'educationLevel' && filterValue?.operation && (
        <div className="profile-selector__field">
          <Text className="profile-selector__label">Végzettség:</Text>
          <Select
            className="profile-select"
            placeholder="Végzettség"
            defaultValue={filterValue?.value}
            optionFilterProp="children"
            onChange={value => handleChange({ value, idx, field: 'value' })}
          >
            <Option value="8_altalanos">8 általános</Option>
            <Option value="szakmunkas">Szakmunkás</Option>
            <Option value="szakkozep">Szakközép</Option>
            <Option value="gimnazium">Gimnázium</Option>
            <Option value="felsooktatas">Egyetem</Option>
          </Select>
        </div>
      )}
      {filterValue?.field === 'county' && filterValue?.operation && (
        <div className="profile-selector__field">
          <Text className="profile-selector__label">Megye:</Text>
          <Select
            className="profile-select"
            placeholder="Megye"
            defaultValue={filterValue?.value}
            optionFilterProp="children"
            onChange={value => handleChange({ value, idx, field: 'value' })}
          >
            <Option value="baranya">Baranya</Option>
            <Option value="bk">Bács-Kiskun</Option>
            <Option value="szakkozep">Békés</Option>
            <Option value="baz">Borsod-Abaúj-Zemplén</Option>
            <Option value="budapest">Budapest</Option>
            <Option value="cscs">Csongrád-Csanád</Option>
            <Option value="fejer">Fejér</Option>
            <Option value="gyms">Győr-Moson-Sopron</Option>
            <Option value="hb">Hajdú-Bihar</Option>
            <Option value="heves">Heves</Option>
            <Option value="jnsz">Jász-Nagykun-Szolnok</Option>
            <Option value="ke">Komárom-Esztergom</Option>
            <Option value="nograd">Nógrád</Option>
            <Option value="pest">Pest</Option>
            <Option value="somogy">Somogy</Option>
            <Option value="szszb">Szabolcs-Szatmár-Bereg</Option>
            <Option value="tolna">Tolna</Option>
            <Option value="vas">Vas</Option>
            <Option value="veszprem">Veszprém</Option>
            <Option value="zala">Zala</Option>
          </Select>
        </div>
      )}
      {filterValue?.field === 'gender' && filterValue?.operation && (
        <div className="profile-selector__field">
          <Text className="profile-selector__label">Nem:</Text>
          <Select
            className="profile-select"
            placeholder="Nem"
            defaultValue={filterValue?.value}
            optionFilterProp="children"
            onChange={value => handleChange({ value, idx, field: 'value' })}
          >
            <Option value="male">Férfi</Option>
            <Option value="female">Nő</Option>
            <Option value="other">Egyéb</Option>
          </Select>
        </div>
      )}
      {filterValue?.field === 'settlementType' && filterValue?.operation && (
        <div className="profile-selector__field">
          <Text className="profile-selector__label">Lakhely:</Text>
          <Select
            className="profile-select"
            placeholder="Lakhely"
            defaultValue={filterValue.value}
            optionFilterProp="children"
            onChange={value => handleChange({ value, idx, field: 'value' })}
          >
            <Option value="nem_mo">Nem magyarország</Option>
            <Option value="kozseg">Község</Option>
            <Option value="varos">Város</Option>
            <Option value="megyeszekhely">Megyeszékhely</Option>
            <Option value="fovaros">Főváros</Option>
          </Select>
        </div>
      )}
    </div>
  );
};

export default ProfileSelector;
