import React from 'react';
import { Spin } from 'antd';

import './page-component.style.css';

const PageLoader = () => {
  return (
    <div className="page-loader" data-testid="page-loader">
      <Spin size="large" />
    </div>
  );
};

export default PageLoader;
