export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = () => ({
  name: 'password-reset-form',
  items: [
    {
      type: 'password',
      name: 'password',
      label: 'Új jelszó',
      rules: [{ required: true, message: 'Kötelező mező' }],
    },
  ],
  submitBtnLabel: 'Mentés',
});
