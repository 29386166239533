import React from 'react';
import moment from 'moment';
import { Typography, Select, DatePicker } from 'antd';
import './activity-selector.style.css';
const { Option } = Select;
const { Text } = Typography;

const ActivitySelector = ({ handleChange, filterValue = {}, idx }) => {
  return (
    <div className="activity-selector">
      <div className="activity-selector__field">
        <Text className="activity-selector__label">Szűrő:</Text>
        <Select
          className="activity-select"
          placeholder="Szűrőmező"
          defaultValue={filterValue.field}
          optionFilterProp="children"
          onChange={value => handleChange({ value, idx, field: 'field' })}
        >
          <Option value="registrationDate">Regisztráció dátuma</Option>
          <Option value="lastLoginDate">Utolsó bejelentkezés dátuma</Option>
          <Option value="lastVoteDate">Utolsó szavazat dátuma</Option>
        </Select>
      </div>
      {filterValue?.field && (
        <div className="profile-selector__field">
          <Text className="profile-selector__label">Operátor:</Text>
          <Select
            placeholder="Reláció"
            className="profile-select"
            defaultValue={filterValue.operation}
            optionFilterProp="children"
            onChange={value => handleChange({ value, idx, field: 'operation' })}
          >
            <Option value="<">&lt;</Option>
            <Option value=">">&gt;</Option>
            <Option value="=">=</Option>
            <Option value="!=">!=</Option>
          </Select>
        </div>
      )}
      {filterValue?.field && filterValue?.operation && (
        <div className="activity-selector__field">
          <Text className="activity-selector__label">Dátum:</Text>
          <DatePicker
            className="activity-select"
            defaultValue={
              filterValue.value ? moment(filterValue.value) : undefined
            }
            onChange={(date, dateString) => {
              handleChange({
                value: dateString,
                idx,
                field: 'value',
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ActivitySelector;
