import React, { useState } from 'react';
import { Modal, Select } from 'antd';

const { Option } = Select;

const QuestionSelectorPopup = ({
  handleCancel,
  handleSave,
  answer,
  questions,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(
    answer?.next_question_id
  );
  return (
    <Modal
      title="Ugrás a következő kérdésre"
      visible={true}
      onOk={() => {
        handleSave({
          answerId: answer.id,
          questionId: selectedQuestion,
        });
      }}
      onCancel={handleCancel}
    >
      <Select
        defaultValue={answer?.next_question_id}
        allowClear
        style={{ width: '100%' }}
        onChange={value => setSelectedQuestion(value)}
      >
        {questions.map(question => {
          let disabled = false;
          if (question.answers.find(a => a.id === answer.id)) {
            disabled = true;
          }
          return (
            <Option key={question.id} value={question.id} disabled={disabled}>
              {question.content}
            </Option>
          );
        })}
      </Select>
    </Modal>
  );
};

export default QuestionSelectorPopup;
