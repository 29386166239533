import React from 'react';
import { Typography, Select } from 'antd';
import './base-selector.component.css';

const { Option } = Select;
const { Text } = Typography;

const BaseSelector = ({ handleChange, filterValue = {}, idx }) => {
  return (
    <div className="base-selector">
      <div className="base-selector__field">
        <Text className="base-selector__label">Típus:</Text>
        <Select
          placeholder="Szűrő típusa"
          className="base-select"
          defaultValue={filterValue.type}
          optionFilterProp="children"
          onChange={value => handleChange({ value, idx, field: 'type' })}
        >
          <Option value="activity">Aktivitás</Option>
          <Option value="profile">Profil</Option>
          <Option value="vote">Válasz</Option>
        </Select>
      </div>
      {filterValue?.type && (
        <div className="base-selector__field">
          <Text className="base-selector__label">Kapcsolat:</Text>
          <Select
            className="base-select"
            placeholder="Kapcsolat"
            defaultValue={filterValue.relation}
            optionFilterProp="children"
            onChange={value => handleChange({ value, idx, field: 'relation' })}
          >
            <Option value="and">És</Option>
            {idx !== 0 && <Option value="or">Vagy</Option>}
          </Select>
        </div>
      )}
    </div>
  );
};

export default BaseSelector;
