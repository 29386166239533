import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './user-table.config.js';
import { USER_LIST } from '../../constants/apiPath';

const UserTable = ({ onDelete }) => {
  return (
    <ApiTable
      url={USER_LIST}
      getTableConfig={getTableConfig}
      defaultOrder={'descend'}
      defaultOrderField={'createdAt'}
      defaultFilterField={'email'}
      defaultFilterValue={''}
      actions={{
        onDelete,
      }}
      size={'middle'}
    />
  );
};

export default UserTable;
